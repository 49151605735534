import { formatDate } from "../../utils/date.util";
import { toCurrency } from "../../utils/number.util";

const dateFormatOptions = {day: "2-digit", month: "2-digit", year: "numeric"};

export const getColumnDef = (accountsMap) => [
  { id: "cash_flow_date", label: "DATE", displayFn: (data) => formatDate(data, dateFormatOptions) },  
  ...(!!accountsMap ? [{id: "accountId", label: "ACCOUNT", displayFn: data => accountsMap[data]?.account_name ?? "---"}] : []),
  { id: "investment", label: "INVESTMENT" },
  { id: "title", label:  "TITLE" },
  { id: "amount", label: "AMOUNT", displayFn: toCurrency }
];

export const getAccountFilterControlDef = (investmentCompanies, filteredAccounts) => {
  const def = {
  investmentFilterId: { 
    options: [{id: 0, name: "All Investments"}, ...investmentCompanies],
    valueFn: v => v.id,
    labelFn: v => v.name
  },
  accountFilterId: {
    options: [{id: 0, account_name: "All Accounts"}, ...filteredAccounts],
    valueFn: v => v.id,
    labelFn: v => v.account_name
  },
  typeFilterId: {
    options: ["Capital Calls and Distributions", "Capital Calls", "Distributions"],
    valueFn: (v, i) => i,
    labelFn: v => v
  },
  daysFilter: {
    options: [-1, 365, 180, 90, 60, 30],
    valueFn: v => v,
    labelFn: v => v === -1 ? "All" : `Last ${v} Days`
  }}
  
  !!filteredAccounts.length ? delete def.investmentFilterId : delete def.accountFilterId;
  return def;
}